/**
 * Created by tatarinov on 03.08.16.
 *
 * Генерируется событие argillaFilterUpdated
 */

;(function ($, undefined) {
  'use strict';

  var pluginName = 'argillaFilter';
  var pluginData = 'jquery_plugin_' + pluginName;
  var pluginDefaults = {
    showMouseLoader: false,
    filterKey: '',
    submitType: 'history',  //( 'history', 'listViewUpdate', 'custom')
    listViewId: '',
    allowedRemoveElements: true,
    clearFilterButtonId: '',
    ajaxUpdateSelectors: [],
    filterUrlClass: '',
    noAjaxUrlClass: 'js-url-no-ajax', // если класс установлен, то происходит прямой переход по url
    debug: false,
  };

  var Plugin = (function () {
    function Plugin(element, options) {
      this.element = $(element);
      this.config = $.extend(false, pluginDefaults, options || {});
      this.init();
    }

    return Plugin;
  }());

  $.extend(Plugin.prototype, {
    init: function () {
      this._debugMessage('init');
      this.formSelector = '#' + this.element.attr('id');
      this._attachEventHandlers();
      this._detectSubmitType();
    },

    //Обновление компонента после замены в DOM
    reinit: function () {
      this.element = $(this.formSelector);
    },

    ajaxSubmit: function (data, url) {
      var urlData = url ? [url, ''] : this._buildUrl(data);
      var submitUrl = urlData[0];
      var submitData = $.deparam(urlData[1]);
      var url = new URL(window.location);
      var plugin = this;

      if (url.searchParams.get('filter') && !submitUrl.includes('filter=opened')) {
        submitUrl += (submitUrl.includes('?') ? '&' : '?') + 'filter=opened';
      }

      this._debugMessage('submitType ' + this.config.submitType);

      if( this.config.showMouseLoader ) {
        if( $.isMouseLoaderActive() ) {
          $.mouseLoader(false);
        }
        $.mouseLoader(true);
      }
      switch (this.config.submitType) {
        case 'history':
          window.History.pushState({data : submitData}, document.title, submitUrl);
          break;

        case 'listViewUpdate':
          var listView = $('#' + this.config.listViewId);
          listView.yiiListView.update(listView.attr('id'), {'url': submitUrl, 'data': submitData});
          break;

        case 'custom':
          $.post(submitUrl, submitData, function (response) {
            plugin._updateElements(response);
            plugin.afterAjaxUpdate(plugin.element.attr('id', response));
          }, 'html');
          break;
        case 'url':
          break;
      }
    },

    clear: function () {
      this.ajaxSubmit({});
    },

    afterAjaxUpdate: function (id, response) {
      this.reinit();
      $('body').trigger('argillaFilterUpdated', [id, response]);

      if (this.config.showMouseLoader && $.isMouseLoaderActive()) {
        $.mouseLoader(false);
      }
    },

    _buildUrl: function (data) {
      data += (data ? '&' : '') + encodeURIComponent(this.config.filterKey + '[submit]') + '=1';
      var action = this.element.attr('action').split('?');
      return [action[0], decodeURIComponent(data)];
    },

    _detectSubmitType: function () {
      if (this.config.submitType != 'custom' && this.config.submitType != 'history' && this.config.submitType != 'listViewUpdate' && this.config.submitType != 'url' ) {
        alert('Не правильное значение submitType ' + this.config.submitType);
        throw DOMException();
      }

      if (this.config.submitType == 'custom')
        return;

      if (this.config.submitType == 'url')
        return;

      var isListView = $('#' + this.config.listViewId).length;
      var isAllowedHistory = window.History.enabled;

      if (this.config.submitType == 'history') {
        if (isAllowedHistory && isListView)
          return;

        if (isListView)
          this.config.submitType = 'listViewUpdate';
        else
          this.config.submitType = 'custom';
      }

      if (this.config.submitType == 'listViewUpdate') {
        if (isListView)
          return;
      }

      this.config.submitType = 'custom';
    },

    _attachEventHandlers: function () {
      this._debugMessage('attachEventHandlers');

      var plugin = this;

      $('body').on('click', '.' + this.config.filterUrlClass, function(e) {
        if( plugin.config.submitType == "url" )
          return;

        if($(this).hasClass(plugin.config.noAjaxUrlClass))
        {
          return true;
        }

        e.preventDefault();
        plugin.ajaxSubmit({}, $(this).attr('href'));
      });

      $('body').on('sliderUrlChanged', function(event, url) {
        plugin.ajaxSubmit({}, url);
      });

      $('body').on('yiiListViewUpdated', function (event, id, data) {
        if (plugin.config.listViewId == id) {
          plugin.afterAjaxUpdate(id, data);
        }
      });

      $('body').on('change', this.formSelector + ' select, ' + this.formSelector + ' input', function (e) {
        if( $(this).hasClass('js-not-submit-on-change') ) {
          e.preventDefault();
          return false;
        }

        plugin._debugMessage('event change');
        plugin.ajaxSubmit(plugin.element.serialize(), $(this).data('url'));
      });

      $('body').on('submit', this.formSelector, function (e) {
        plugin._debugMessage('submit hook');

        var postSubmitUrl = plugin.element.data('post-submit-url');
        if( postSubmitUrl == undefined ) {
          e.preventDefault();
          plugin.ajaxSubmit(plugin.element.serialize());
        }

        plugin.element.attr('action', postSubmitUrl);
      });

      $('body').on('click', '#' + this.config.clearFilterButtonId, function (e) {
        e.preventDefault();
        plugin.clear();
      });

      if (this.config.allowedRemoveElements) {
        $('body').on('click', '.js-remove-btn', function (e) {
          e.preventDefault();
          var removeId = $(this).data('remove');
          plugin._removeElement(removeId)
        });
      }
    },

    _removeElement: function (removeId) {
      var plugin = this;
      var removeElement = $('#' + removeId);

      if (removeElement.length && removeElement.attr('type') !== 'hidden') {
        removeElement.click();
      }
      else {
        this.element.find('#' + removeId).val('');
        this.ajaxSubmit(plugin.element.serialize());
      }
    },

    _debugMessage: function ($message) {
      if (this.config.debug == true)
        console.log($message);
    },

    _updateElements: function (response) {
      var content = $('<div>' + response + '</div>');
      this.element.replaceWith(content.find(this.formSelector));

      for (var i in this.config.ajaxUpdateSelectors) {
        var selector = this.config.ajaxUpdateSelectors[i];
        $(selector).replaceWith(content.find(selector));
      }
    }
  });

  $.fn[pluginName] = function (options) {
    var args = arguments;

    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$.data(this, pluginData)) {
          $.data(this, pluginData, new Plugin(this, $.extend(options, $(this).data())));
        }
      });
    }
    else if (typeof options === 'string') {
      var returns;

      this.each(function () {
        var instance = $.data(this, pluginData);

        if (instance instanceof Plugin && typeof instance[options] === 'function') {
          returns = instance[options].apply(instance, Array.prototype.slice.call(args, 1));
        }
      });

      return returns !== undefined ? returns : this;
    }
  };
}(jQuery));
